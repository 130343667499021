import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Flex from "../../../packages/Flex/src";
import Div from "../../../packages/Div/src";
import Select from "../../../packages/Select/src";
import Autocomplete from "../../../packages/Autocomplete/src";
import RatioContainer from "../../../packages/RatioContainer/src";

var ControlsBarPattern = function ControlsBarPattern() {
  return React.createElement(PatternLayout, null, React.createElement(Container, {
    size: "sm",
    mt: "5rem"
  }, React.createElement(Flex, {
    alignItems: "center",
    mb: "1.5rem",
    justifyContent: "space-between"
  }, React.createElement(Div, {
    width: ["100%", "30%"],
    mb: ["0.75rem", "0"]
  }, React.createElement(Autocomplete, {
    options: [{
      value: "AGO",
      label: "Angola"
    }, {
      value: "COL",
      label: "Colombia"
    }, {
      value: "SWE",
      label: "Sweden"
    }, {
      value: "TZA",
      label: "Tanzania"
    }]
  })), React.createElement(Div, {
    width: ["100%", "auto"]
  }, React.createElement(Select, {
    width: "100%",
    defaultValue: "1",
    options: [{
      value: "1",
      label: "Option 1"
    }, {
      value: "2",
      label: "Option 2"
    }, {
      value: "3",
      label: "Option 3"
    }]
  }))), React.createElement(RatioContainer, {
    ratio: 2 / 3,
    bg: "grey.100"
  }, "Visualisation")));
};

export default ControlsBarPattern;